define("discourse/plugins/poll/discourse/services/polls-service", ["exports", "@ember/service", "@ember/object", "discourse/lib/ajax"], function (_exports, _service, _object, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _service.default.extend({
    store: (0, _service.inject)(),
    polls: (0, _object.computed)(function () {
      return (0, _ajax.ajax)("/polls/poll_list.json").then(response => {
        return response;
      });
    })
  });
});
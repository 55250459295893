define("discourse/plugins/poll/discourse/routes/admin-plugins-polls", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _object, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsPolls extends _discourse.default {
    model() {
      if (!this.currentUser?.admin) {
        return {
          model: null
        };
      }

      //return [{id: 1, title: 'Grand Old Mansion',period: 'Veruca Salt'},{id: 2,title: 'asdgsdg', period: 'asdg'}];

      return (0, _ajax.ajax)("/polls/poll_list.json").then(model => {
        return model;
      });
    }
  }
  _exports.default = AdminPluginsPolls;
});
define("discourse/plugins/poll/discourse/components/poll-list-widget", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _service, _object, _didInsert, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PollListWidgetComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "poll", [_tracking.tracked]))();
    #poll = (() => (dt7948.i(this, "poll"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "polls", [_tracking.tracked], function () {
      return [];
    }))();
    #polls = (() => (dt7948.i(this, "polls"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "inFrontpage", [_tracking.tracked], function () {
      return false;
    }))();
    #inFrontpage = (() => (dt7948.i(this, "inFrontpage"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "pollsService", [_service.service]))();
    #pollsService = (() => (dt7948.i(this, "pollsService"), void 0))();
    // Fetch and update polls
    get getPolls() {
      return this.pollsService.polls;
    }
    fetchPolls(element1) {
      this.getPolls.then(result1 => {
        let polls1 = result1.polls.filter(poll1 => poll1.status === "open");
        this.polls = polls1;
        if (polls1.length > 0) {
          this.poll = polls1[0];
        }
        console.log('Fetched polls:', polls1);
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "fetchPolls", [_object.action]))();
    get isFrontpage() {
      return this.router.currentRouteName === 'discovery.latest' || this.router.currentRouteName === 'index';
    }
    static #_7 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.isFrontpage}}
        <div class="poll-widget-list" {{didInsert this.fetchPolls}}>
          <h3>Active polls</h3>
          <br>
          {{#if this.polls.length}}
            <ul>
              {{#each this.polls as |poll|}}
                <li>Poll: <a href="{{poll.post_url}}">{{poll.post_topic_title}} - {{poll.title}}</a></li>
              {{/each}}
            </ul>
          {{else}}
            <p>{{i18n "poll.admin.none"}}</p>
          {{/if}}
        </div>
        {{/if}}
      
    */
    {
      "id": "vp6KGPho",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"isFrontpage\"]],[[[1,\"    \"],[11,0],[24,0,\"poll-widget-list\"],[4,[32,0],[[30,0,[\"fetchPolls\"]]],null],[12],[1,\"\\n      \"],[10,\"h3\"],[12],[1,\"Active polls\"],[13],[1,\"\\n      \"],[10,\"br\"],[12],[13],[1,\"\\n\"],[41,[30,0,[\"polls\",\"length\"]],[[[1,\"        \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"polls\"]]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[12],[1,\"Poll: \"],[10,3],[15,6,[29,[[30,1,[\"post_url\"]]]]],[12],[1,[30,1,[\"post_topic_title\"]]],[1,\" - \"],[1,[30,1,[\"title\"]]],[13],[13],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,2],[12],[1,[28,[32,1],[\"poll.admin.none\"],null]],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"poll\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/poll/discourse/components/poll-list-widget.js",
      "scope": () => [_didInsert.default, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PollListWidgetComponent;
});